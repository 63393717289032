body { background-color: #ffffff; }
body { color: var(--clr-388); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h2 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-389);
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Noto Sans';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 12px;

@media #{$large-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 27;
font-family: 'Noto Sans';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 26px;

}
}
summary {
font-family: 'Noto Sans';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: 600;
font-style: normal;
line-height: 2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Noto Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-389);}
a:hover {color: var(--clr-394);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-388);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-388);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-388);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-389);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-389);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-389);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: var(--clr-391);}
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-392);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-392);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-392);
 }
.MES8 {
background-color: var(--clr-392);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-392);
 }
.MES9 {
background-color: var(--clr-392);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-389);
 }
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-393);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-393);
color: var(--clr-389);
 }
.MES11 {
background-color: var(--clr-393);
color: var(--clr-389);
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: var(--clr-389);
 }
 }
a.MEC11 { color: var(--clr-389);
&:hover { color: var(--clr-394);}
 }
cite.MEC11{
color: var(--clr-389);
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-394);
color: #ffffff;
&:hover { color: var(--clr-389);}
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-394);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-394);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: var(--clr-389);}
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
 }
.MES14 {
font-size: 12.8px;
 }
cite.MEC14{
font-size: 12.8px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-392);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-393);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-392);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-393);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-388);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-395);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-396);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-391);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: transparent;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: var(--clr-394);}
background-color: #ffffff;
background-clip: padding-box;
&:hover {background-color: var(--clr-394);}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: var(--clr-394);}
}}
& .slick-dots{bottom:100px;
@media #{$medium-up} {
bottom: 98px;};
@media #{$large-up} {
bottom: 25px;};
} }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-390);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-388);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-393);
color: var(--clr-388);
border-radius: 8px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-392);
font-size: 12.8px;
 }
.MES22 {
background-color: var(--clr-392);
font-size: 12.8px;
 }
cite.MEC22{
font-size: 12.8px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 50px;

}
 }
.MES23 {
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 50px;

}
 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-392);
padding: 20px;

@media #{$large-up} {
padding: 40px 50px;

}
 }
.MES24 {
background-color: var(--clr-392);
padding: 20px;

@media #{$large-up} {
padding: 40px 50px;

}
 }
a.MEC24 { color: var(--clr-394);
&:hover { color: var(--clr-389);}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-394) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-388);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-393) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-388);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-393) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-388);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 16px; }; }
 }
a.MEC26 { color: var(--clr-388);
&:hover { color: var(--clr-395);}
 }
cite.MEC26{
color: var(--clr-388);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* White BG Transparent:27 */
.MES27 {
background-color: var(--clr-391);
 }
.MES27 {
background-color: var(--clr-391);
 }
/* Popup form Window:28 */
.MES28 {
background-color: #ffffff;
color: #696969;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-393);
 }
.MES28 {
background-color: #ffffff;
color: #696969;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-393);
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: var(--clr-389);
 }
 }
cite.MEC28{
color: #696969;
}
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-392);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-392);}
 }
/* White Text Panel:30 */
.MES30 {
background-color: var(--clr-400);
color: #ffffff;
 }
.MES30 {
background-color: var(--clr-400);
color: #ffffff;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
cite.MEC30{
color: #ffffff;
}
/* Header Top Banner:31 */
.MES31 {
background-color: var(--clr-389);
color: #ffffff;
 }
.MES31 {
background-color: var(--clr-389);
color: #ffffff;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
a.MEC31 { color: #ffffff;
&:hover { color: var(--clr-390);}
 }
cite.MEC31{
color: #ffffff;
}
/* :32 */
.MES32 {
background-color: #ffffff;
padding: 5px 0;

border-width: 1px 0;
border-style: solid;
border-color: var(--clr-392) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES32 {
background-color: #ffffff;
padding: 5px 0;

border-width: 1px 0;
border-style: solid;
border-color: var(--clr-392) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Menu Button Cat:33 */
.MES33 {
background-color: var(--clr-389);
&:hover, &.hover { background-color: var(--clr-390);}
color: #ffffff;
border-radius: 6px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px 10px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES33 {
background-color: var(--clr-389);
&:hover, &.hover { background-color: var(--clr-390);}
color: #ffffff;
border-radius: 6px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px 10px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Shade 2:34 */
.MES34 {
background-color: var(--clr-397);
color: #ffffff;
 }
/* Shade 2:35 */
.MES35 {
background-color: var(--clr-397);
color: #ffffff;
 }
.MES35 {
background-color: var(--clr-397);
color: #ffffff;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
 }
cite.MEC35{
color: #ffffff;
}
/* login buttons:36 */
.MES36 {
background-color: var(--clr-389);
&:hover {background-color: var(--clr-394);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 8px;
padding: 5px;

@media #{$large-up} {
padding: 12px 5px;

}
 }
/* Heading White panel:37 */
.MES37 {
background-color: #ffffff;
color: var(--clr-389);
 }
.MES37 {
background-color: #ffffff;
color: var(--clr-389);
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: var(--clr-389);
 }
 }
a.MEC37 { color: var(--clr-389);
&:hover { color: var(--clr-390);}
 }
cite.MEC37{
color: var(--clr-389);
}
/* Product Tile:38 */
.MES38 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: var(--clr-398);
&:hover { border-color: var(--clr-390); }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES38 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: var(--clr-398);
&:hover { border-color: var(--clr-390); }
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Dark:39 */
.MES39 {
background-color: var(--clr-390);
color: var(--clr-389);
&:hover { color: var(--clr-394);}
 }
/* Dark:40 */
.MES40 {
background-color: var(--clr-390);
color: var(--clr-389);
 }
.MES40 {
background-color: var(--clr-390);
color: var(--clr-389);
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: var(--clr-389);
 }
 }
a.MEC40 { color: var(--clr-389);
&:hover { color: var(--clr-394);}
 }
cite.MEC40{
color: var(--clr-389);
}
/* White Border Panel:41 */
.MES41 {
font-size: 18.4px;
@media #{$large-up} {
font-size: 19.2px;
};
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES41 {
font-size: 18.4px;
@media #{$large-up} {
font-size: 19.2px;
};
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
cite.MEC41{
font-size: 18.4px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Footer Banner:42 */
.MES42 {
background-color: var(--clr-389);
color: #ffffff;
padding: 5px 0;

@media #{$large-up} {
padding: 10px 0;

}
border-width: 3px 0;
border-style: solid;
border-color: var(--clr-390) transparent transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES42 {
background-color: var(--clr-389);
color: #ffffff;
padding: 5px 0;

@media #{$large-up} {
padding: 10px 0;

}
border-width: 3px 0;
border-style: solid;
border-color: var(--clr-390) transparent transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: #ffffff;
 }
 }
a.MEC42 { color: #ffffff;
&:hover { color: var(--clr-394);}
 }
cite.MEC42{
color: #ffffff;
}
/* Accordion:43 */
details.MES43 {
color: var(--clr-389);
& > article { color: var(--clr-389);
 }
& > summary { color: var(--clr-389);
 }
 }
/* Search button:44 */
.MES44 {
background-color: #ffffff;
&:hover {background-color: var(--clr-390);}
color: var(--clr-389);
&:hover { color: #ffffff;}
border-radius: 0 8px 8px 0;
padding: 8px 20px;

 }
/* Enquiry buttons:45 */
.MES45 {
background-color: var(--clr-390);
&:hover {background-color: var(--clr-389);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 8px;
padding: 5px 10px;

@media #{$large-up} {
padding: 12px 20px;

}
 }
/* Popup Menu:46 */
.MES46 {
background-color: #ffffff;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-393);
 }
.MES46 {
background-color: #ffffff;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-393);
 }
/* Popoutmenu List style:47 */
.MES47 {
background-color: var(--clr-394);
&:hover, &.hover { background-color: var(--clr-390);}
padding: 5px;

 }
.MES47 {
background-color: var(--clr-394);
&:hover, &.hover { background-color: var(--clr-390);}
padding: 5px;

 }
/* Main Menu2:48 */
nav.me-Menu.MES48 {
& .menu-item.MEC48, & .menu-item.MEC48 > div.MEC48{ & > a.MEC48{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC48{color: var(--clr-391);
}
 }
&.horizontal > .menu-item.MEC48 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC48 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC48 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC48 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Carousel slider:50 */
.MES50 {
& .slider-arrow {color: var(--clr-390);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Dark Navy:51 */
.MES51 {
background-color: var(--clr-399);
padding: 5px;

@media #{$large-up} {
padding: 0;

}
border-width: 2px 0;
border-style: solid;
border-color: #dcdcdc;
 }
.MES51 {
background-color: var(--clr-399);
padding: 5px;

@media #{$large-up} {
padding: 0;

}
border-width: 2px 0;
border-style: solid;
border-color: #dcdcdc;
 }
a.MEC51 { color: #ffffff;
&:hover { color: var(--clr-391);}
 }
/* :52 */
.MES52 {
background-color: var(--clr-399);
color: #ffffff;
&:hover { color: var(--clr-391);}
padding: 0 10px;

 }
/* Image Shape:53 */
.MES53 {
border-radius: 15px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES53 {
border-radius: 15px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Part Form :54 */
.me-block > .field-wrap.MES54 { @include placeholder(var(--clr-390) var(--clr-389));
input.field-input, textarea.field-input, select.field-input {border-color:var(--clr-394) var(--clr-394) var(--clr-394) var(--clr-394) !important;
border-style: Array !important;
border-width: transparentpx !important;

color:var(--clr-390) var(--clr-389) !important;}
::-webkit-input-placeholder { color: var(--clr-390) var(--clr-389); opacity: 0.5 !important;}
:-moz-placeholder {color: var(--clr-390) var(--clr-389); opacity: 0.5 !important;} 
::-moz-placeholder {color: var(--clr-390) var(--clr-389); opacity: 0.5 !important;}
:-ms-input-placeholder {color: var(--clr-390) var(--clr-389); opacity: 0.5 !important;}

& label {  }
 }
/* File List 2:55 */
.MES55 {
background-color: var(--clr-394);
&:hover, &.hover { background-color: var(--clr-390);}
color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES55 {
background-color: var(--clr-394);
&:hover, &.hover { background-color: var(--clr-390);}
color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
cite.MEC55{
color: #ffffff;
}
/* White Text Panel2:56 */
.MES56 {
background-color: transparent;
color: #ffffff;
 }
.MES56 {
background-color: transparent;
color: #ffffff;
h1.MEC56, h2.MEC56, h3.MEC56, h4.MEC56, h5.MEC56, h6.MEC56 { color: #ffffff;
 }
h1.MEC56 { @media #{$medium-up} { font-size: 42px; }; }
h1.MEC56 { @media #{$large-up} { font-size: 52.5px; }; }
h2.MEC56 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC56 { @media #{$large-up} { font-size: 52.5px; }; }
h3.MEC56 { @media #{$medium-up} { font-size: 36px; }; }
h3.MEC56 { @media #{$large-up} { font-size: 45px; }; }
h4.MEC56 { @media #{$medium-up} { font-size: 26.4px; }; }
h4.MEC56 { @media #{$large-up} { font-size: 33px; }; }
h5.MEC56 { @media #{$medium-up} { font-size: 21.6px; }; }
h5.MEC56 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC56 { @media #{$medium-up} { font-size: 19.2px; }; }
h6.MEC56 { @media #{$large-up} { font-size: 24px; }; }
 }
a.MEC56 { color: #ffffff;
&:hover { color: var(--clr-390);}
 }
cite.MEC56{
color: #ffffff;
}
/* Video Panel:57 */
.MES57 {
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
.MES57 {
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Accordian MyAccount:58 */
details.MES58 {
& > summary { color: var(--clr-389);
font-size:12.6px;
@media #{$large-up} {
font-size:12.78px;
}
 }
 }
/* Banner Button:59 */
.MES59 {
color: #ffffff;
&:hover { color: var(--clr-390);}
font-size: 9.6px;
@media #{$medium-up} {
font-size: 10.92px;
};
@media #{$large-up} {
font-size: 16px;
};
border-radius: 8px;
padding: 5px 10px;

@media #{$large-up} {
padding: 10px 25px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Outof buttons:60 */
.MES60 {
background-color: var(--clr-388);
&:hover {background-color: var(--clr-394);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 8px;
padding: 5px 10px;

@media #{$large-up} {
padding: 12px 20px;

}
 }
/* Related Text:61 */
.MES61 {
font-size: 12px;
 }
.MES61 {
font-size: 12px;
 }
cite.MEC61{
font-size: 12px;
}
/* :62 */
details.MES62 {
& > summary{background-color: #f2f2f2;
&:hover {background-color: #dcdcdc;}
}
& > summary{padding: 10px 15px;}

& > article{padding: 15px;}

 }
/* Filter applybuttons:63 */
.MES63 {
background-color: var(--clr-389);
&:hover {background-color: var(--clr-394);}
color: #ffffff;
border-radius: 8px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Filter Panel:64 */
.MES64 {
background-color: var(--clr-393);
color: var(--clr-389);
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES64 {
background-color: var(--clr-393);
color: var(--clr-389);
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC64, h2.MEC64, h3.MEC64, h4.MEC64, h5.MEC64, h6.MEC64 { color: var(--clr-389);
 }
 }
a.MEC64 { color: var(--clr-389);
&:hover { color: var(--clr-394);}
 }
cite.MEC64{
color: var(--clr-389);
}
/* Category Panel:65 */
.MES65 {
background-color: var(--clr-392);
color: var(--clr-389);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES65 {
background-color: var(--clr-392);
color: var(--clr-389);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC65, h2.MEC65, h3.MEC65, h4.MEC65, h5.MEC65, h6.MEC65 { color: var(--clr-389);
 }
 }
cite.MEC65{
color: var(--clr-389);
}
